import type { SeoEntry } from "frontend/contentful/schema/semantics";
import { Head, HeadProps } from "design-system/components/primitives/head/head";
import { createMediaAssetProps } from "./media-asset.props";
import { useContentful } from "frontend/hooks/use-contentful";
import { EntryRef } from "frontend/contentful/schema/sys";
import { usePageProps } from "frontend/hooks/use-page-props";
import { GOOGLE_TAG_IDS } from "frontend/lib/constants";
import { useHeadTitleDescription } from "./head-title-description";
import { DataLayerEventData } from "frontend/hooks/use-data-layer";

interface ContentfulHeadProps extends HeadProps {
  entry?: EntryRef<SeoEntry>;
}

export function ContentfulHead({ entry, ...props }: ContentfulHeadProps) {
  const { request } = usePageProps();
  const { getEntry, site, isEditorMode, isPageLoaded } = useContentful();

  entry = getEntry(entry);

  const { title, description } = useHeadTitleDescription({
    title: props.title ?? entry?.fields.title ?? "",
    description: props.description ?? entry?.fields.description ?? "",
  });

  const openGraphMedia = getEntry(entry?.fields?.openGraphMedia);
  const mediaProps = createMediaAssetProps(openGraphMedia);

  let canonicalUrl = entry?.fields.canonicalUrl;
  if (!canonicalUrl) canonicalUrl = `https://${request.host}${request.path}`;

  let gtagId: string | undefined = GOOGLE_TAG_IDS.DEFAULT;
  if (site?.fields.domain === "exed") gtagId = GOOGLE_TAG_IDS.EXED;
  if (site?.fields.domain === "online") gtagId = GOOGLE_TAG_IDS.ONLINE;
  if (site?.fields.domain === "my") {
    gtagId = GOOGLE_TAG_IDS.MY;
  }

  const pageLoadEventData: DataLayerEventData = { ...props.pageLoadEventData };

  if (site?.fields.slug) {
    pageLoadEventData.profile_code =
      site.fields.slug === "/" ? site.fields.domain : site.fields.slug;
  }

  if (request.query.gtag) {
    if (request.query.gtag === "false") gtagId = undefined;
    else gtagId = request.query.gtag;
  }
  // ?gtag=false should remove gtag script - also disable in editor mode
  if (!isPageLoaded || isEditorMode) gtagId = undefined;

  return (
    <Head
      gtagId={gtagId}
      title={title}
      description={description}
      canonicalUrl={canonicalUrl}
      openGraphDescription={entry?.fields.openGraphDescription}
      image={mediaProps?.image}
      noIndex={entry?.fields.noIndex}
      noFollow={entry?.fields.noFollow}
      convertId={entry?.fields.convertId}
      {...props}
      pageLoadEventData={pageLoadEventData}
    />
  );
}
