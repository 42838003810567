const remove = [/<[^>]*>?/gm, /\u00AD/g, "&lt;", "&gt;"] as const;

const replace = [["&quot;", '"', "&apos;", "'", "&amp;", "&"]] as const;

export function stripHtml(html: string): string {
  let result = html;

  // Protect the mark tags returned from highlighted search results
  result = result
    .replaceAll("<mark>", "[mark]")
    .replaceAll("</mark>", "[/mark]");

  for (const rm of remove) {
    result = result.replaceAll(rm, "");
  }
  for (const [key, value] of replace) {
    result = result.replaceAll(key, value);
  }

  // Return the mark tags to original state
  result = result
    .replaceAll("[mark]", "<mark>")
    .replaceAll("[/mark]", "</mark>");

  return result;
}
