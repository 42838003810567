import { ReactNode } from "react";
import { MediaAssetImageProps } from "../media-asset/media-asset-image";
import { cloudinary } from "design-system/utils/cloudinary";
import { useStoreSelector } from "design-system/store/store";
import { DataLayerEventData } from "frontend/hooks/use-data-layer";

export type ArticleStructuredData = Partial<{
  name: string;
  author: string;
  datePublished: string;
  image: string;
  articleSection: string;
  articleBody: string;
  url: string;
}>;

export type EventStructuredData = Partial<{
  name: string;
  startDate: string;
  endDate?: string;
  eventAttendanceMode?: string;
  eventStatus?: string;
  location: Array<object>;
  image?: string;
  description?: string;
  offers?: object;
  performer?: object;
  organizer?: object;
}>;

export interface HeadProps {
  gtagId?: string;
  title?: string;
  description?: string;
  canonicalUrl?: string;
  type?: string;
  siteName?: string;
  image?: MediaAssetImageProps;
  twitterCard?: "summary" | "summary_large_image" | "app" | "player";
  twitterCreator?: string;
  twitterSite?: string;
  metaOverride?: Record<string, string | undefined>;
  children?: ReactNode;
  openGraphDescription?: string;
  noIndex?: boolean;
  noFollow?: boolean;
  convertId?: string;
  pageLoadEventData?: DataLayerEventData;
  articleStructuredData?: ArticleStructuredData;
  eventStructuredData?: EventStructuredData;
}

export function Head({
  gtagId,
  title = "Harvard Business School",
  description = "Harvard Business School",
  siteName = "Harvard Business School",
  canonicalUrl,
  type = "website",
  image,
  twitterCard = "summary",
  twitterCreator = "@HarvardHBS",
  twitterSite = "@HarvardHBS",
  metaOverride,
  children,
  openGraphDescription,
  noFollow,
  noIndex,
  convertId,
  pageLoadEventData,
  articleStructuredData,
  eventStructuredData,
}: HeadProps) {
  const Container = useStoreSelector((store) => store.framework.Head);
  const imageSrc = cloudinary(image?.src);

  const meta = {
    "og:title": title,
    "og:description": openGraphDescription ?? description,
    "og:url": canonicalUrl,
    "og:type": type,
    "og:site_name": siteName,
    "og:image": imageSrc,
    "og:image:width": image?.width,
    "og:image:height": image?.height,
    "og:image:alt": image?.alt,
    "twitter:title": title,
    "twitter:description": description,
    "twitter:card": twitterCard,
    "twitter:creator": twitterCreator,
    "twitter:site": twitterSite,
    "twitter:image": imageSrc,
    "twitter:image:alt": image?.alt,
    ...metaOverride,
  };

  return (
    <Container>
      <link rel="preconnect" href="https://cloudinary.hbs.edu" />
      {noIndex && <meta key="robots-noindex" name="robots" content="noindex" />}
      {noFollow && (
        <meta key="robots-nofollow" name="robots" content="nofollow" />
      )}
      <title>{title}</title>
      {description ? <meta name="description" content={description} /> : null}
      {Object.entries(meta).map(
        ([property, value]) =>
          value && <meta property={property} content={value} key={property} />,
      )}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {convertId && (
        <script
          src={`https://cdn-4.convertexperiments.com/js/${convertId}.js`}
        />
      )}
      {/* https://developers.google.com/search/docs/appearance/structured-data/article */}
      {articleStructuredData && (
        <script
          key="article-structured-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Article",
              publisher: {
                "@type": "Organization",
                name: "Harvard Business School",
              },

              url: articleStructuredData.url,
              name: articleStructuredData.name,
              image: articleStructuredData.image,
              articleBody: articleStructuredData.articleBody,
              datePublished: articleStructuredData.datePublished,
              articleSection: articleStructuredData.articleSection,
              author: articleStructuredData.author && {
                "@type": "Person",
                name: articleStructuredData.author,
              },
            }),
          }}
        ></script>
      )}
      {/* https://developers.google.com/search/docs/appearance/structured-data/event */}
      {eventStructuredData && (
        <script
          key="event-structured-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Event",
              name: eventStructuredData.name,
              startDate: eventStructuredData.startDate,
              endDate: eventStructuredData.endDate,
              eventAttendanceMode: eventStructuredData.eventAttendanceMode,
              eventStatus: eventStructuredData.eventStatus,
              location: eventStructuredData.location,
              image: eventStructuredData.image,
              description: eventStructuredData.description,
              offers: eventStructuredData.offers,
              organizer: eventStructuredData.organizer,
            }),
          }}
        ></script>
      )}

      {/* https://github.com/harvard-hbs/analytics-contentful-cms-data-layers/blob/2a2c659dd844557f1d42dc7ad162dc06ac49bca9/global/Page%20Load.md */}
      {pageLoadEventData && (
        <script
          key="gtm-init"
          dangerouslySetInnerHTML={{
            __html: `
                window.dataLayer = window.dataLater|| [];
                window.dataLayer.push({ event: "page_load", event_data: ${JSON.stringify(
                  pageLoadEventData,
                )} });`,
          }}
        />
      )}

      {/* https://developers.google.com/tag-platform/tag-manager/datalayer#installation */}
      {gtagId && (
        <script
          key="gtm-script"
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${gtagId}');
            `,
          }}
        />
      )}
      {children}
    </Container>
  );
}
