import { documentToString } from "frontend/contentful/lib/document-to-string";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import {
  CtaBannerEntry,
  RichTextEntry,
  SectionIntroTextEntry,
} from "frontend/contentful/schema/blocks";
import {
  ParticipantStoryEntry,
  ProfilePageEntry,
} from "frontend/contentful/schema/pages";
import { TimelineEventEntry } from "frontend/contentful/schema/primitives";
import {
  PersonEntry,
  SchoolLeadershipPersonDetailsEntry,
} from "frontend/contentful/schema/semantics";
import {
  ContentfulEntry,
  ContentfulRichText,
  EntryRef,
} from "frontend/contentful/schema/sys";
import { useContentful } from "frontend/hooks/use-contentful";
import { usePageProps } from "frontend/hooks/use-page-props";
import { stripHtml } from "../utils/strip-html";

const DESCRIPTION_MAX_LENGTH = 160;

function documentToDescription(document?: ContentfulRichText) {
  if (
    document &&
    typeof document === "object" &&
    Array.isArray(document.content)
  ) {
    return stripHtml(documentToString(document).replace(/\s\s+/g, " "));
  }
  return "";
}

interface UseHeadFallbackValuesProps {
  title: string;
  description: string;
}

export function useHeadTitleDescription({
  title,
  description,
}: UseHeadFallbackValuesProps) {
  const { entry: pageEntryRef } = usePageProps();
  const { getEntry, site } = useContentful();

  const pageEntry = getEntry(pageEntryRef);

  if (!title || !description) {
    // extract a default title from the topper title
    if (pageEntry?.fields) {
      if ("topper" in pageEntry.fields) {
        if (
          pageEntry.fields.topper &&
          typeof pageEntry.fields.topper === "object"
        ) {
          const topper = getEntry(
            pageEntry.fields.topper as EntryRef<ContentfulEntry>,
          );

          if (topper) {
            if (!title) {
              if (
                "title" in topper.fields &&
                typeof topper.fields.title === "string"
              ) {
                title = topper.fields.title;
              }
            }

            if (!description) {
              if ("subtitle" in topper.fields && topper.fields.subtitle) {
                if (typeof topper.fields.subtitle === "string") {
                  description = topper.fields.subtitle;
                } else if (typeof topper.fields.subtitle === "object") {
                  description = documentToDescription(
                    topper.fields.subtitle as ContentfulRichText,
                  );
                }
              }
            }
          }
        }
      }
      // if no topper title, use page title
      if (!title && pageEntry.fields.title) {
        title = pageEntry.fields.title;
      }
      if (
        !title &&
        isContentType<ParticipantStoryEntry>("participantStory", pageEntry)
      ) {
        if (pageEntry.fields.subTitle) title = pageEntry.fields.subTitle;
      }
      // if no topper description, pull out of body content - require a description longer than 30 characters
      if (!description || description.length < 30) {
        // account for profile pages separately
        if (isContentType<ProfilePageEntry>("profilePage", pageEntry)) {
          if (pageEntry.fields.profile) {
            const entry = getEntry(pageEntry.fields.profile);

            if (
              isContentType<PersonEntry>("person", entry) &&
              entry.fields.bio
            ) {
              description = documentToDescription(entry.fields.bio);
            }
            if (
              isContentType<SchoolLeadershipPersonDetailsEntry>(
                "schoolLeadershipPersonDetails",
                entry,
              )
            ) {
              const person = getEntry(entry.fields.person);
              if (person?.fields.bio) {
                description = documentToDescription(person.fields.bio);
              }
            }
          }
        } else {
          let content: Array<ContentfulEntry> = [];
          // body is used for article page
          if (
            "body" in pageEntry.fields &&
            Array.isArray(pageEntry.fields.body)
          ) {
            content = pageEntry.fields.body as Array<ContentfulEntry>;
          }
          // blocks is used for every other page type
          if (
            "blocks" in pageEntry.fields &&
            Array.isArray(pageEntry.fields.blocks)
          ) {
            content = pageEntry.fields.blocks as Array<ContentfulEntry>;
          }

          // break after first find
          for (const block of content) {
            const entry = getEntry(block);
            // set description to first DESCRIPTION_MAX_LENGTH characters of specific blocks
            if (
              isContentType<RichTextEntry>("richText", entry) &&
              entry.fields.text
            ) {
              description = documentToDescription(entry.fields.text);
              break;
            }
            if (
              isContentType<CtaBannerEntry>("ctaBanner", entry) &&
              entry.fields.subtitle
            ) {
              description = documentToDescription(entry.fields.subtitle);
              break;
            }
            if (
              isContentType<SectionIntroTextEntry>("sectionIntroText", entry)
            ) {
              if (entry.fields.intro) {
                description = documentToDescription(entry.fields.intro);
                break;
              } else if (entry.fields.bodyText) {
                description = documentToDescription(entry.fields.bodyText);
                break;
              }
            }
            if (isContentType<TimelineEventEntry>("timelineEvent", entry)) {
              if (entry.fields.title) {
                description = documentToDescription(entry.fields.title);
                break;
              }
              if (entry.fields.description) {
                description = documentToDescription(entry.fields.description);
                break;
              }
            }
          }
        }
      }
    }

    if (site?.fields.title) {
      if (title && site.fields.title !== title) {
        // append the site to the end of the title
        title = title + " | " + site.fields.title;
      } else {
        // default to the site name for a page title
        title = site.fields.title + " | " + "Harvard Business School";
      }
    }
  }

  if (!title) title = "Harvard Business School";
  if (!description) description = title;

  description = description.substring(0, DESCRIPTION_MAX_LENGTH);

  return { title, description };
}
